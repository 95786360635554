<template>
  <div class="page">
    <el-card>
      <template slot="header">
        <span class="title">{{ this.$route.params && this.$route.params.id ? '编辑' : '新增' }}礼包</span>
        <div class="button-back">
          <el-button @click="() => {
          $router.go(-1);
        }
          " size="small" class="back_btn">
            <img src="@/assets/back.png" alt="" />
          </el-button>
        </div>
      </template>

      <div v-loading="loading">
        <div class="content-goods-publish">
          <el-form :model="baseInfoForm" status-icon :rules="baseInfoFormRule" label-position="right" ref="baseInfoForm"
            label-width="130px" class="demo-ruleForm">
            <div class="base-info-item">
              <div>
                <el-form-item label="礼包名称" prop="gift_name" class="goods-name-width">
                  <el-input v-model="baseInfoForm.gift_name" @change="() => {
          baseInfoForm.gift_name = baseInfoForm.gift_name.trim();
        }
          " :maxlength="32" :minlength="1" placeholder="1-32个字符"></el-input>
                </el-form-item>

                <el-form-item label="前端礼包名称" prop="web_gift_name" class="goods-name-width">
                  <el-input v-model="baseInfoForm.web_gift_name" @change="() => {
          baseInfoForm.web_gift_name =
            baseInfoForm.web_gift_name.trim();
        }
          " :maxlength="32" :minlength="1" placeholder="1-32个字符"></el-input>
                </el-form-item>
                <el-form-item label="礼包分类" prop="category_path">
                  <el-cascader clearable style="width: 430px;" v-model="baseInfoForm.category_path"
                    :options="GifCategoryOptions" :props="GifProps" placeholder="请选择礼包分类" class="cas-select" />
                </el-form-item>
                <el-form-item label="所属公司" prop="company" class="goods-name-width">
                  <el-input v-model="baseInfoForm.company"></el-input>
                </el-form-item>
                <el-form-item label="区域偏好">
                  <div class="check_taglabel">
                    <el-tag style="margin-right:10px" size="medium" @close="handleClose(tag, 2)" closable
                      v-for="tag in checkAreaList" :key="tag.label_name">
                      {{ tag.label_name }}
                    </el-tag>
                  </div>
                  <div class="select_taglabel">
                    <el-button size="mini" :disabled="disabledArea(tag)" style="margin-right:10px"
                      @click="addgiftlabel(tag, 2)" :type="disabledArea(tag) ? 'info' : 'primary'"
                      v-for="tag in areaList" :key="tag.label_name" plain>
                      {{ tag.label_name }}
                    </el-button>
                  </div>
                </el-form-item>
                <el-form-item label="标签">
                  <div class="check_taglabel">
                    <el-tag style="margin-right:10px" @close="handleClose(tag, 1)" v-for="tag in checkLabelList"
                      :key="tag.label_name" size="medium" closable>
                      {{ tag.label_name }}
                    </el-tag>
                  </div>
                  <div class="select_taglabel">
                    <el-button size="mini" :disabled="disabledLabel(tag)" @click="addgiftlabel(tag, 1)"
                      :type="disabledLabel(tag) ? 'info' : 'primary'" v-for="tag in giftlabelList" :key="tag.label_name"
                      plain>{{
          tag.label_name
        }}</el-button>
                  </div>
                </el-form-item>
                <el-form-item label="礼包图片" prop="goods_gallery" style="width: 90%; text-align: left">
                  <el-upload class="avatar-uploader goods-images" :class="baseInfoForm.goods_gallery_list.length >= 5 &&
          'no-plus-icon'
          " :action="`${MixinUploadApi}?scene=goods`" list-type="picture-card" multiple :limit="5"
                    :on-exceed="handleExceed" :file-list="goodsGalleryList" :on-preview="handlePictureCardPreview"
                    :before-upload="beforeAvatarUpload" :on-remove="handleRemove" :on-success="handleSuccess">
                    <!-- <span style="display: none;"  v-model="baseInfoForm.goods_gallery"></span> -->
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                    <div slot="tip" class="el-upload__tip">
                      建议图片尺寸比例为4:3，大小不超1MB，只限png/jpg格式，最多上传5张图片。
                    </div>
                  </el-upload>
                  <el-dialog :visible.sync="dialogImage">
                    <img width="100%" :src="dialogImageUrl" alt />
                  </el-dialog>
                </el-form-item>
                <div>
                  <el-form-item label="礼包商品来源" prop="catering" style="width: 100%;text-align: left;">
                    <el-radio-group @input="deliveryChange"
                      :disabled="comboId !== '' && comboId !== null && goodsData.length != 0"
                      v-model="baseInfoForm.delivery_method">
                      <el-tooltip effect="dark" placement="top">
                        <el-radio :label="1">平台邮寄商品</el-radio>
                        <i class="el-icon-warning-outline"></i>
                        <div slot="content">由平台配送，只可邮寄到用户地址</div>
                      </el-tooltip>

                      <el-tooltip effect="dark" placement="top">
                        <el-radio v-if="shop_self_mailed_goods_open" :label="3">商城邮寄商品</el-radio>
                        <i class="el-icon-warning-outline"></i>
                        <div slot="content">由商城配送，只可邮寄到用户地址</div>
                      </el-tooltip>

                      <el-tooltip effect="dark" placement="top">
                        <el-radio v-if="shop_self_goods_open" :label="2">商城自提商品</el-radio>
                        <i class="el-icon-warning-outline"></i>
                        <div slot="content">由商城配送，只可到指定网点自提</div>
                      </el-tooltip>

                      <!-- <el-tooltip effect="dark" placement="top">
                        <el-radio :label="4">蛋糕类自提商品</el-radio>
                        <i class="el-icon-warning-outline"></i>
                        <div slot="content">由平台配送，只可到指定网点自提</div>
                      </el-tooltip> -->

                    </el-radio-group>
                  </el-form-item>
                  <el-form-item v-if="[2].includes(baseInfoForm.delivery_method)" label="自提网点选择" prop="pickup_stations"
                    style="width: 70%;text-align: left;">
                    <el-table ref="multipleTable" stripe border max-height="250" :data="tableData" style="width: 100%"
                      @selection-change="handleSelectionChange" @select-all="handleSelectAll">
                      <el-table-column type="selection" width="55" al>
                      </el-table-column>
                      <el-table-column width="180" align="center" label="自提点名称">
                        <template slot-scope="{row}">{{ row.name }}</template>
                      </el-table-column>
                      <el-table-column align="center" label="地址">
                        <template slot-scope="{row}">
                          <span>{{ row.province + row.city + row.county + (row.town ? row.town : '') + row.addr
                            }}</span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-form-item>
                  <el-form-item v-if="[2].includes(baseInfoForm.delivery_method)" label="自提时间设置" prop="catering"
                    style="width: 40%;text-align: left;">
                    <el-radio-group v-model="baseInfoForm.type">
                      <el-radio :label="1">指定日期自提</el-radio>
                      <el-radio :label="2">下单后指定时间段自提</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <div v-if="[2].includes(baseInfoForm.delivery_method) && baseInfoForm.type == 1">
                    <el-form-item label="自提时间" style="width: 60%;text-align: left;">
                      <el-alert :closable="false" title="指定日期自提：不同时间下单购买的订单，需在同一时间段内进行自提。" type="warning">
                      </el-alert>
                    </el-form-item>
                    <el-form-item label="自提日期" prop="starting_date" style="width: 40%;text-align: left;">
                      <el-date-picker :picker-options="timeOptions" style="width: 220px" value-format="timestamp"
                        v-model="order_time_range" @change="datatimeChange" type="daterange" align="center"
                        size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
                        end-placeholder="结束日期"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="自提时间段" prop="time_slot" style="width: 40%;text-align: left;">
                      <el-time-picker is-range value-format="H:mm" v-model="baseInfoForm.time_slot" range-separator="至"
                        start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
                      </el-time-picker>
                    </el-form-item>
                    <el-form-item label="下单截止时间设置" prop="catering" style="width: 42%;text-align: left;">
                      <el-radio-group v-model="deadline" @input="deadlineChange">
                        <el-radio :label="1">不限</el-radio>
                        <el-radio :label="2">指定日期</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="deadline == 2" label="截止日期" prop="order_deadline_time"
                      style="width: 40%;text-align: left;">
                      <el-date-picker :picker-options="timeOptions" style="width: 220px" format="yyyy-MM-dd HH:mm"
                        value-format="timestamp" v-model="baseInfoForm.order_deadline_time" type="datetime"
                        align="center" size="medium" :editable="false" unlink-panels range-separator="-"
                        start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                    </el-form-item>
                  </div>
                  <div v-if="[2].includes(baseInfoForm.delivery_method) && baseInfoForm.type == 2">
                    <el-form-item label="" prop="catering" style="width: 70%;text-align: left;">
                      <el-alert :closable="false" title="下单后指定时间段自提：下单后，需在【下单时间+备货时间】后的指定天数内、指定时间范围内进行自提。"
                        type="warning">
                      </el-alert>
                    </el-form-item>
                    <el-form-item label="备货时间" prop="lead_time" style="width: 40%;text-align: left;">
                      <el-input-number :min="1" v-model.trim="baseInfoForm.lead_time" step-strictly></el-input-number>
                    </el-form-item>
                    <el-form-item label="自提天数范围" prop="pickup_days" style="width: 40%;text-align: left;">
                      <div style="display: inline-block;">
                        <el-input-number :min="1" v-model.trim="baseInfoForm.pickup_days"
                          step-strictly></el-input-number>
                      </div>
                      <span style="padding-left: 12px;">天内</span>
                    </el-form-item>
                    <el-form-item label="自提时间段" prop="time_slot" style="width: 40%;text-align: left;">
                      <el-time-picker :editable="false" is-range value-format="H:mm" v-model="baseInfoForm.time_slot"
                        range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
                      </el-time-picker>
                    </el-form-item>
                  </div>
                </div>
                <el-form-item label="选择商品" prop="goods_data" class="goods-name-width">
                  <el-button type="primary" size="small" @click="showDialog = true">选择商品</el-button>
                </el-form-item>

                <!-- 商品选择区 start -->
                <el-form-item style="width: 100%" class="goods-select">
                  <en-table-layout :tableData="goodsData" style="width: 100%" ref="selectTableWrap">
                    <template slot="table-columns">
                      <el-table-column label="商品图片" width="90" class-name="goods-cover-wrapper" fixed="left">
                        <template slot-scope="{ row }">
                          <el-popover placement="right" trigger="hover">
                            <img :src="row.thumbnail" alt style="width: 300px" />
                            <img :src="row.thumbnail" class="goods-cover" alt slot="reference" />
                          </el-popover>
                        </template>
                      </el-table-column>

                      <el-table-column label="商品名称" :show-overflow-tooltip="true" width="200" fixed="left">
                        <template slot-scope="scope">
                          <span v-html="scope.row.goods_name"></span>
                        </template>
                      </el-table-column>
                      <el-table-column label="前端展示图片" width="130" class-name="goods-cover-wrapper">
                        <template slot-scope="{ row, $index }">
                          <div style="position: relative">
                            <div class="changBtn">
                              <el-upload class="upload-demo" :action="`${MixinUploadApi}?scene=goods`"
                                :on-success="thumbnailSuccess" :show-file-list="false">
                                <el-button @click="setIndex($index, 1)" style="
                                    padding: 0;
                                    color: #fff;
                                    font-size: 12px;
                                  " type="text">更换图片</el-button>
                              </el-upload>
                            </div>
                            <el-popover placement="right" trigger="hover">
                              <img :src="row.web_thumbnail
          ? row.web_thumbnail
          : row.thumbnail
          " alt style="width: 300px" />
                              <img :src="row.web_thumbnail
          ? row.web_thumbnail
          : row.thumbnail
          " class="goods-cover" alt slot="reference" />
                            </el-popover>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column label="前端名称" :show-overflow-tooltip="true" width="200">
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.goods_alias"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column label="商品编号" :show-overflow-tooltip="true" width="200">
                        <template slot-scope="{ row }">
                          <span v-html="row.self_operated !== 1 && row.goods_source === 1
          ? row.ext_goods_id
          : row.sn
          "></span>
                        </template>
                      </el-table-column>
                      <el-table-column label="礼包内数量" width="100">
                        <template slot-scope="scope">
                          <el-input v-model.trim="scope.row.num"
                            oninput="value=value.replace(/[^0-9]/g,'');if(value>100)value=100" maxlength="3"
                            @input="moveOver"></el-input>
                          <span v-if="scope.row.num >= 100" style="color:red;font-size:12px">最大不超100</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="可换商品数" width="100" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                          <span v-html="scope.row.exchange_goods_num" v-if="scope.row.exchange_goods_num"></span>
                          <div v-else style="text-align: center">0</div>
                        </template>
                      </el-table-column>
                      <el-table-column label="商品来源" width="100">
                        <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
                        <template slot-scope="scope">
                          <template v-if="scope.row.self_operated">
                            <template v-if="scope.row.self_operated === 0">
                              <span v-if="scope.row.goods_source === 0">
                                精选优品
                              </span>
                              <span v-else>
                                京东商品
                              </span>
                            </template>
                            <template v-else>
                              自有商品
                            </template>
                          </template>
                          <template v-else>
                            <span v-if="scope.row.goods_source === 0">精选优品</span>
                            <span v-if="[5,9].includes(scope.row.goods_source)">自有商品</span>
                            <span v-if="scope.row.goods_source === 1">京东商品</span>
                            <span v-if="scope.row.goods_source === 3">蛋糕商品</span>
                          </template>
                        </template>
                      </el-table-column>
                      <el-table-column key="supplier_name" width="200" label="所属供应商"
                        v-if="$store.getters.isSupplierName">
                        <template slot-scope="scope">{{
          scope.row.supplier_name || scope.row.seller_name
        }}</template>
                      </el-table-column>
                      <el-table-column key="price" label="会员价" width="100" prop="price" v-if="MixinIsFormEnterprise">
                        <template slot-scope="scope">{{
          scope.row.price | unitPrice("￥")
        }}</template>
                      </el-table-column>

                      <el-table-column key="mktprice" label="市场价" width="100" prop="market">
                        <template slot-scope="scope">{{
            scope.row.mktprice | unitPrice("￥")
          }}</template>
                      </el-table-column>

                      <el-table-column key="enterprise_revise_price"
                        v-if="!(!this.MixinIsFormEnterprise && (this.ispetroChina||this.$store.getters.shopInfo.shop_id==1595))" label="分销价" width="100"
                        prop="enterprisePrice">
                        <template slot-scope="scope">{{
          scope.row.enterprise_revise_price | unitPrice("￥")
        }}</template>
                      </el-table-column>
                      <!-- v-if="MixinIsFormEnterprise" -->
                      <el-table-column key="enterprise_profit" v-if="MixinIsFormEnterprise" label="毛利率" width="100"
                        prop="profit">
                        <template slot-scope="scope">{{ scope.row.enterprise_profit }}%</template>
                      </el-table-column>

                      <el-table-column label="销售价" width="100" prop="revisePrice">
                        <template slot-scope="scope">{{
          scope.row.revise_price | unitPrice("￥")
        }}</template>
                      </el-table-column>
                      <!-- v-if="MixinIsFormEnterprise" -->
                      <el-table-column key="shop_profit" v-if="MixinIsFormEnterprise" label="利润率" width="100"
                        prop="shopProfit">
                        <template slot-scope="scope">{{ scope.row.shop_profit }}%</template>
                      </el-table-column>

                      <el-table-column label="库存" width="100" fixed="right">
                        <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
                        <template slot-scope="scope">
                          <template v-if="scope.row.goods_source === 1">
                            <template v-if="scope.row.enable_quantity > 0">有货</template>
                            <template v-else>无货</template>
                          </template>
                          <template v-else>{{ scope.row.enable_quantity || 0 }}件</template>
                        </template>
                      </el-table-column>
                      <el-table-column label="状态" width="120" fixed="right">
                        <template slot-scope="scope">
                          <span v-if="scope.row.shop_goods_status === 0 ||
          scope.row.disabled === 0
          ">
                            已下架
                            <br/>
                            <span style="color: red">（已失效）</span>
                          </span>
                          <span v-else>已上架</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="操作" fixed="right" width="320">
                        <template slot-scope="scope">
                          <el-button v-if="manual_exchange_goods" :disabled="scope.row.not_put" size="mini"
                            type="primary" @click="changeFun(scope.row)">替换商品</el-button>
                          <el-button size="mini" type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                          <el-button v-if="scope.row.seq !== goodsData[0].seq" type="warning"
                            :disabled="scope.row.not_put" icon="el-icon-top" circle size="mini" @click="
          movement(scope.row, 'up', 'moveComboGoodsTh')
          "></el-button>
                          <el-button v-if="scope.row.seq !==
          goodsData[goodsData.length - 1].seq
          " type="warning" :disabled="scope.row.not_put" icon="el-icon-bottom" circle size="mini" @click="
          movement(scope.row, 'down', 'moveComboGoodsTh')
          "></el-button>
                          <div v-if="scope.row.not_put" style="color: red; font-size: 12px">
                            (保存挑选商品后再选择替换商品)
                          </div>
                        </template>
                      </el-table-column>
                    </template>
                  </en-table-layout>
                </el-form-item>
                <el-form-item label="合计" style="width: 100%">
                  <el-table :data="totalData" style="width: 100%">
                    <el-table-column v-if="!(!this.MixinIsFormEnterprise && (this.ispetroChina||this.$store.getters.shopInfo.shop_id==1595))"
                      prop="enterprise_sum_price" label="分销总价" :render-header="addRedStar">
                      <template>
                        <el-input placeholder="请输入分销总价" v-model.trim="baseInfoForm.enterprise_sum_price"
                          oninput="value = (value.match(/\d+(.\d{0,2})?/) || [''])[0]"
                          @input="getPrice(1, 'change_enterprise_sum_price')"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="shop_sum_price" label="销售总价" :render-header="addRedStar">
                      <template>
                        <el-input placeholder="请输入销售总价" v-model.trim="baseInfoForm.shop_sum_price"
                          oninput="value = (value.match(/\d+(.\d{0,2})?/) || [''])[0]"
                          @input="getPrice(1, 'change_shop_sum_price')"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column v-if="MixinIsFormEnterprise" prop="distribution_profit" label="分销利润率">
                      <template slot="header">
                        <el-tooltip effect="dark" placement="top">
                          <label class="cursor-pointer"><i class="el-icon-warning-outline"></i>分销利润率</label>
                          <div slot="content">
                            分销利润率 = (分销总价-会员总价) /分销总价*100%
                          </div>
                        </el-tooltip>
                      </template>
                      <template slot-scope="scope">{{ scope.row.distribution_profit }}%</template>
                    </el-table-column>
                    <el-table-column v-if="MixinIsFormEnterprise" prop="price_profit" label="销售利润率">
                      <template slot="header">
                        <el-tooltip effect="dark" placement="top">
                          <label class="cursor-pointer"><i class="el-icon-warning-outline"></i>销售利润率</label>
                          <div slot="content">
                            销售利润率 = (销售总价-会员总价) /销售总价*100%
                          </div>
                        </el-tooltip>
                      </template>
                      <template slot-scope="scope">{{ scope.row.price_profit }}%</template>
                    </el-table-column>
                    <el-table-column v-if="MixinIsFormEnterprise" prop="sum_price" label="会员总价" />
                    <el-table-column prop="market_sum_price" label="市场总价" />
                    <el-table-column v-if="MixinIsFormEnterprise" prop="market_profit" label="市场利润率">
                      <template slot="header">
                        <el-tooltip effect="dark" placement="top">
                          <label class="cursor-pointer"><i class="el-icon-warning-outline"></i>市场利润率</label>
                          <div slot="content">
                            市场利润率 = (市场总价-会员总价) /市场总价*100%
                          </div>
                        </el-tooltip>
                      </template>
                      <template slot-scope="scope">{{ scope.row.market_profit }}%</template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
                <!-- <el-form-item label="替换商品置顶设置" prop="catering" style="width: 100%;">
                  <el-switch v-model="baseInfoForm.auto_replace_replaceable_goods" :active-value="1" :inactive-value="0"></el-switch>
                  <br/>
                  <span style="color: #999; font-size: 12px;">提示：开启后，当移动端前台礼包内商品失效时，系统将自动从其可替换商品列表中（有可替换商品时）选择第一个商品推荐给C端用户。</span>
                </el-form-item> -->
               
                <el-form-item label="正常品是否显示替换功能" class="goods-intro" label-width="180px" style="width: 100%">
                  <el-switch v-model="baseInfoForm.normal_show_ai_replace_button" :active-value="1" :inactive-value="0"></el-switch>
                  <br/>
                  <span style="color: #999; font-size: 12px;">提示：开启后，移动端前台礼包内商品只要有可替换商品，均会显示【AI推荐】的功能按钮；关闭后，仅在商品失效时，才会再显示【AI推荐】的功能按钮。</span>
                </el-form-item>
                <el-form-item label="礼包详情" class="goods-intro" style="width: 85%">
                  <UE ref="ue" :defaultMsg="baseInfoForm.intro"></UE>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <!--底部-->
        <div class="footer">
          <el-button :loading="createGoodsButtonLoading" type="primary" size="small" @click="aboveGoods">{{
          ($route.params && $route.params.id || comboId) ? "确认修改" : "确认创建"
        }}</el-button>

        </div>
      </div>
      <!-- 商品选择器 -->
      <en-goods-picker-fx :isSoldSeparatelyEnable="false" :pickupGift="pickupGift" :show="showDialog"
        :showChooseBtn="false" :default-data="goodsData" @close="showDialog = false" @chooseAll="allChoose" />
      <!-- 可替换商品选择器 -->
      <en-goods-picker-fx :isSoldSeparatelyEnable="false" :replaceType="replaceType" :pickupGift="pickupGift"
        :comboId="comboId" :originGoodsId="changeRow.goods_id" :show="showChangeDialog" :showChooseBtn="false"
        :default-data="pageData.data" @close="showChangeDialog = false" @chooseAll="allChangeChoose" />
      <x-dialog :proxy="changeDialog">
        <div v-if="changeDialog" style="height:auto;">
          <en-table-layout :tableData="pageData.data" :loading="loading" @sort-change="handleSortChange"
            style="height:480px;overflow: hidden;" ref="tableWrap">
            <template slot="toolbar">
              <el-form-item>
                <el-button type="primary" size="small" @click="openrelpace">选择商品</el-button>
              </el-form-item>
              <el-form-item>
                <el-input style="width: 300px" size="medium" placeholder="输入关键字" v-model="params.keyWord" clearable>
                  <el-select v-model="key_word" slot="prepend" placeholder="请选择" style="width: 140px">
                    <el-option label="商品名称" value="goods_name"></el-option>
                    <el-option label="商品编号" value="goods_sn"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="changeFun(changeRow)" type="primary" size="small">搜索</el-button>
              </el-form-item>
            </template>
            <template slot="table-columns">
              <el-table-column label="商品图片" width="60" class-name="goods-cover-wrapper" fixed="left">
                <template slot-scope="{ row }">
                  <el-popover placement="right" trigger="hover">
                    <img :src="row.thumbnail" alt style="width: 300px" />
                    <img :src="row.thumbnail" class="goods-cover" alt slot="reference" />
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column label="前端展示图片" width="70" class-name="goods-cover-wrapper" fixed="left">
                <template slot-scope="{ row, $index }">
                  <div style="position: relative">
                    <div class="changBtn">
                      <el-upload class="upload-demo" :action="`${MixinUploadApi}?scene=goods`"
                        :on-success="thumbnailSuccess" :show-file-list="false">
                        <el-button @click="setIndex($index, 2)" style="padding: 0; color: #fff; font-size: 12px"
                          type="text">更换图片</el-button>
                      </el-upload>
                    </div>
                    <el-popover placement="right" trigger="hover">
                      <img :src="row.web_thumbnail ? row.web_thumbnail : row.thumbnail
          " alt style="width: 300px" />
                      <img :src="row.web_thumbnail ? row.web_thumbnail : row.thumbnail
          " class="goods-cover" alt slot="reference" />
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商品名称" :show-overflow-tooltip="true" width="200" fixed>
                <template slot-scope="scope">
                  <span v-html="scope.row.goods_name"></span>
                </template>
              </el-table-column>
              <el-table-column label="前端名称" width="200">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" :content="scope.row.goods_alias" placement="top">
                    <el-input v-model="scope.row.goods_alias"></el-input>
                  </el-tooltip>
                  <!-- <el-input v-model="scope.row.goods_alias"></el-input> -->
                </template>
              </el-table-column>
              <el-table-column label="商品编号" :show-overflow-tooltip="true" width="200">
                <template slot-scope="scope">
                  <span v-html="scope.row.sn"></span>
                </template>
              </el-table-column>

              <el-table-column v-if="MixinIsFormEnterprise" label="会员价" width="110" prop="price" sortable="custom"
                :key="'price'">
                <template slot-scope="scope">{{
          scope.row.price | unitPrice("￥")
        }}</template>
              </el-table-column>

              <el-table-column label="市场价" width="110" prop="market" sortable="custom">
                <template slot-scope="scope">{{
            scope.row.mktprice | unitPrice("￥")
          }}</template>
              </el-table-column>

              <el-table-column v-if="!(!this.MixinIsFormEnterprise && (this.ispetroChina||this.$store.getters.shopInfo.shop_id==1595))" label="分销价" width="110"
                prop="enterprisePrice" sortable="custom">
                <template slot-scope="scope">{{
          scope.row.enterprise_revise_price | unitPrice("￥")
        }}</template>
              </el-table-column>

              <!-- <el-table-column
                v-if="MixinIsFormEnterprise"
                label="毛利率"
                width="100"
                prop="profit"
                sortable="custom"
              >
                <template slot-scope="scope"
                  >{{
                    (scope.row.platform_profit &&
                      scope.row.platform_profit.toFixed(2)) ||
                    0
                  }}%</template
                >
              </el-table-column> -->

              <el-table-column label="销售价" width="110" prop="revisePrice" sortable="custom">
                <template slot-scope="scope">
                  <span>{{ scope.row.revise_price | unitPrice("￥") }}</span>
                </template>
              </el-table-column>

              <el-table-column v-if="MixinIsFormEnterprise" label="利润率" width="100" prop="shopProfit" sortable="custom">
                <template slot-scope="scope">{{ scope.row.shop_profit }}%</template>
              </el-table-column>

              <el-table-column label="商品数量" :key="'num'">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.num" oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="160" fixed="right" :key="'opt'">
                <template slot-scope="scope">
                  <el-button size="mini" type="danger" :disabled="!scope.row.delete"
                    @click="handleDeleteCombo(scope.row)">删除</el-button>
                  <el-button v-if="scope.row.seq !== pageData.data[0].seq" type="warning" :disabled="scope.row.not_put"
                    icon="el-icon-top" circle size="mini"
                    @click="movement(scope.row, 'up', 'moveReplaceGoodsTh')"></el-button>
                  <el-button v-if="scope.row.seq !==
          pageData.data[pageData.data.length - 1].seq
          " type="warning" :disabled="scope.row.not_put" icon="el-icon-bottom" circle size="mini"
                    @click="movement(scope.row, 'down', 'moveReplaceGoodsTh')"></el-button>
                </template>
              </el-table-column>
            </template>
          </en-table-layout>
        </div>
      </x-dialog>
    </el-card>
  </div>
</template>

<script>
import * as API_Combo from "@/api/combo-card-shop/combo2";
import * as API_Login from "@/api/login";
import { RegExp } from "@/../ui-utils";
import Sortable from "sortablejs";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import { UE } from "@/components";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import * as API_account from "@/api/account";
import { pickupStationList } from '@/api/shopSetting';
import { getSellerShopExtAuth } from '@/api/account'
import { Foundation } from "@/../ui-utils";
import { getCnpcShopIds } from "@/api/account"
import { $giftsCategory } from '@/pages/goods/services/gifts-category'
import { getGiftLabelList } from '@/api/account';
import * as $GoodsCategoryApi from "@/api/goodsCategory";
export default {
  name: "comboEdit",
  components: {
    [UE.name]: UE,
    EnTableLayout,
    XDialog,
  },
  data () {
    const checkCombo = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("套餐价格不能为空"));
      } else if (value == 0) {
        callback(new Error("套餐价格必须大于零"));
      }
      if (!RegExp.money.test(value)) {
        callback(new Error("请输入数字值"));
      } else {
        // if (parseFloat(value) > parseFloat(this.baseInfoForm.shop_sum_price)) {
        //   callback(new Error("市场价格必须大于等于套餐"));
        // } else {
        callback();
        // }
      }
    };

    const checkGoodsGallery = (rule, value, callback) => {
      setTimeout(() => {
        if (!this.baseInfoForm.goods_gallery) {
          callback(new Error("请选择商品相册"));
        } else {
          callback();
        }
      }, 1000);
    };

    const checkGoodsData = (rule, value, callback) => {
      setTimeout(() => {
        if (!this.goodsData.length) {
          callback(new Error("请选择商品"));
        } else {
          callback();
        }
      }, 1000);
    };
    const checkpickupData = (rule, value, callback) => {
      setTimeout(() => {
        if (this.baseInfoForm.pickup_stations == '' || !this.baseInfoForm.pickup_stations) {
          callback(new Error("请选择门店"));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      seller_id: '',//企业id
      ispetroChina: false,//是否是中石油商城
      deadline: 1,//是否有截止日期
      timeOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
        }
      },
      pickupGift: 1,//  1 普通礼包（包含蛋糕邮寄商品过滤自选商品）   2 自提礼包（自选商品-餐类） 3 邮寄礼包（只显示邮寄商品）自提礼包（蛋糕类-自提商品）
      replaceType: 1,//替换类型 1普通商品   2蛋糕商品
      order_time_range: '',
      checkAreaList: [],//选中的区域偏好
      checkLabelList: [],//选中的礼包标签
      giftlabelList: [],//礼包标签
      areaList: [],//区域偏好
      JDCategory: $giftsCategory.init(),
      key_word: "goods_name",
      changeForm: {},
      changeDialog: $xDialog.create({
        title: "可替换商品",
        width: "960px",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.editChange,
      }),
      thumbnailIndex: "",
      thumbnailIndex2: "",
      MixinIsFormEnterprise: false,
      /** 加载中。。。 */
      loading: false,
      /** 当前商品id*/
      comboId: null,
      /** 商品详情信息提交表单 */
      baseInfoForm: {
        starting_date: "",
        ending_date: "",
        delivery_method: 1,//自提开关
        type: 1,//自提时间设置  1指定日期自提  2下单后指定时间段自提
        category_path: '',
        /** 礼包名称 */
        gift_name: "",
        // 前端礼包名称
        goods_alias: "",
        // 所属公司
        company: "",
        /** 商品相册列表 */
        goods_gallery_list: [],
        /** 用来校验的商品相册 */
        goods_gallery: "",
        /** 套餐价格  */
        combo_price: 0,
        /** 销售总价格 */
        shop_sum_price: 0,
        /** 市场总价格 */
        market_sum_price: 0,
        /** 分销总价格 */
        enterprise_sum_price: 0,
        /** 会员总价 */
        sum_price: 0,
        /** 商品描述 详情 */
        intro: "",
        /** 利润率 */
        profit_price: 0,
        change_combo_price: 0,
        change_market_price: 0,
        change_shop_sum_price: 0,
        change_enterprise_sum_price: 0,
      },
      /** 临时存储商品相册信息 */
      goodsGalleryList: [],
      /** 商品图片放大地址 */
      dialogImageUrl: "",
      /** 是否显示放大图片弹框 */
      dialogImage: false,
      /** 商品详情的校验规则 */
      baseInfoFormRule: {
        web_gift_name: [
          { required: true, message: "请输入礼包名称", trigger: "blur" },
        ],
        gift_name: [
          { required: true, message: "请输入礼包名称", trigger: "blur" },
          {
            whitespace: true,
            message: "礼包名称不可为纯空格",
            trigger: "blur",
          },
          {
            min: 1,
            max: 32,
            message: "长度在 1 到 32 个字符",
            trigger: "blur",
          },
        ],
        goods_alias: [
          { required: true, message: "请输入礼包名称", trigger: "blur" },
          {
            whitespace: true,
            message: "礼包名称不可为纯空格",
            trigger: "blur",
          },
          {
            min: 1,
            max: 32,
            message: "长度在 1 到 32 个字符",
            trigger: "blur",
          },
        ],
        category_path: [
          { required: true, message: '请选择礼包分类', trigger: 'change' },
        ],
        company: [
          { required: true, message: '请填写所属公司', trigger: 'blur' },
        ],
        goods_data: [
          { required: true, validator: checkGoodsData, trigger: "blur" },
        ],
        combo_price: [
          { required: true, message: "请输入套餐价格", trigger: "blur" },
          { validator: checkCombo, trigger: "blur" },
        ],
        shop_sum_price: [
          { required: true, message: "请输入销售总价格", trigger: "blur" },
        ],
        market_sum_price: [
          { required: true, message: "请输入市场总价格", trigger: "blur" },
        ],
        enterprise_sum_price: [
          { required: true, message: "请输入分销总价格", trigger: "blur" },
        ],
        sum_price: [
          { required: true, message: "请输入会员总价格", trigger: "blur" },
        ],
        goods_gallery: [
          { required: true, validator: checkGoodsGallery, trigger: "change" },
        ],
        profit_price: [
          { required: true, message: "请输入利润率", trigger: "blur" },
        ],
        pickup_stations: [
          { required: true, validator: checkpickupData, trigger: "blur" },
        ],
        starting_date: [
          { required: true, message: "请选择自提日期", trigger: "change" },
        ],
        time_slot: [
          { required: true, message: "请选择自提时间段", trigger: "change" },
        ],
        order_deadline_time: [
          { required: true, message: "请选择截止如期", trigger: "blur" },
          { required: true, validator: this.verificationTime, trigger: "blur" }
        ],
        lead_time: [
          { required: true, message: "请选输入备货时间", trigger: "blur" },
        ],
        pickup_days: [
          { required: true, message: "请输入自提天数范围", trigger: "blur" },
        ],
      },
      createGoodsButtonLoading: false,
      goodsData: [],
      totalData: [],
      /** 选择器相关 */
      showDialog: false,
      showChangeDialog: false,
      chooseChangeList: [],
      firstPriceShowFlag: true,
      isPack: true,
      pageData: {
        data: [],
      },
      params: {
        keyWord: "",
        page_no: 1,
        page_size: 20,
      },
      changeRow: {},
      manual_exchange_goods: false,
      moveOver: '',
      tableData: [],//自提门店列表
      shop_self_goods_open: false,//是否开启商城自建开关
      shop_self_mailed_goods_open: false,//是否开启商城自寄开关
      GifCategoryOptions: [],
      GifProps: {
        emitPath: false,
        checkStrictly: true,
        expandTrigger: "hover",
        label: 'name',
        children: 'children',
        value: 'category_path'
      }
    };
  },
  filters: {
    /** 销售状态格式化 */
    marketStatus (row) {
      switch (row.shop_goods_status) {
        case 0:
          return "已下架";
        case 1:
          return "已上架";
      }
    },
  },
  watch: {
    goodsData: function (val,oldVal) {
      // 如果是编辑，价格的第一次回显使用接口返回的价格，而不是客户端自己计算的
      if (
        (this.$route.params &&
          this.$route.params.id &&
          this.firstPriceShowFlag) ||
        (this.comboId && this.firstPriceShowFlag)
      ) {
        this.firstPriceShowFlag = !this.firstPriceShowFlag;
      } else {
        // 如果是编辑，价格的第二次回显使用客户端自己计算价格或者新增 
        let flag;//只更改图片不重新计算价格
        const list = val.length < oldVal.length ? val : oldVal 
        const oldList = val.length < oldVal.length ? oldVal : val 
        list.forEach(((item, index) => {
          if(item.web_thumbnail !== oldList[index].web_thumbnail) flag = true
        })) 
        if(flag) return
        this.getPrice();
      }
    },
    'baseInfoForm.category_path': {
      handler (val) {
        console.log('baseInfoForm.category_path', val, typeof val,);

      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (this.$route.params && this.$route.params.id) {
      this.comboId = this.$route.params.id;
      this.GET_GoodData();
    }
    next();
  },
  async created () {
    console.log(this.$store.getters.shopInfo)
    let shopidList = await getCnpcShopIds()
    shopidList = shopidList.data.split(',')
    this.ispetroChina = shopidList.includes((this.$store.getters.shopInfo.shop_id).toString())
    await pickupStationList({ page_no: 1, page_size: 999, enable_status: 1 }).then(response => {//查询自提网点
      this.loading = false;
      this.tableData = response.data;
    });
  },
  async mounted () {
    //查询区域偏好和标签
    await this.getTagLabel();
    if (sessionStorage.getItem("MixinIsFormEnterprise")) {
      this.MixinIsFormEnterprise = JSON.parse(
        sessionStorage.getItem("MixinIsFormEnterprise")
      );
    }
    this.GifCategoryOptions = await $GoodsCategoryApi.getAllGiftsCategoryLevelList(0)
    console.log(11111111111111111, this.GifCategoryOptions)
    API_Login.getLoginType().then((res) => {
      if (res && res.parentLogin === 1) {
        this.MixinIsFormEnterprise = true;
        sessionStorage.setItem("MixinIsFormEnterprise", true);
      } else sessionStorage.setItem("MixinIsFormEnterprise", false);
      this.$refs.selectTableWrap.$refs.table.doLayout()
    });
    API_account.getShopmsg().then((res) => {
      this.manual_exchange_goods = res.manual_exchange_goods === 1;

    });
    if (this.$route.params && this.$route.params.id) {
      this.comboId = this.$route.params.id;
      this.GET_GoodData();
    }
    this.totalData[0] = this.baseInfoForm;
    let that = this;
    this.moveOver = this.debounce(function () {
      that.getPrice();
    }, 200)
    const shop = await getSellerShopExtAuth(this.$store.getters.shopInfo.shop_id)
    this.seller_id = shop.shop_id;
    this.shop_self_goods_open = shop.shop_self_goods_open == 'OPEN'
    this.shop_self_mailed_goods_open = shop.shop_self_mailed_goods_open == 'OPEN'
  },
  computed: {
    disabledLabel () {
      return function (value) {
        if (!this.checkLabelList.length) return false
        return this.checkLabelList.some(k => k.label_id == value.label_id)
      }
    },
    disabledArea () {
      return function (value) {
        if (this.checkAreaList.length == 0) {
          return false
        }
        return this.checkAreaList.some(k => k.label_id == value.label_id)
      }
    }
  },
  methods: {
    addRedStar (h, { column }) {//表头添加*号
      let _output = [];
      if (['销售总价', '分销总价'].includes(column.label)) {
        _output = [
          h('span', { style: 'color: red' }, '*'),
          h('span', ' ' + column.label)
        ]
      } else {
        _output = [h('span', ' ' + column.label)]
      }
      return _output
    },
    verificationTime (rule, value, callback) {
      if (this.order_time_range && this.baseInfoForm.time_slot) {
        let endtime = Foundation.unixToDate(this.order_time_range[1] / 1000);
        let time1 = endtime.split(' ')[0]

        let datatimes = this.baseInfoForm.time_slot[1];
        let newtime = time1 + ' ' + datatimes;
        console.log(newtime);
        console.log(value / 1000);
        console.log(Foundation.dateToUnix(newtime));
        if (value / 1000 > Foundation.dateToUnix(newtime)) {
          callback(new Error("截止日期不能超出最晚的自提日期"));
        }
      }
      callback();
    },
    deadlineChange (val) {//下单截止日期设置
      if (val == 1) {
        this.baseInfoForm.order_deadline_time = null
      }
    },
    deliveryChange (val) {
      console.log(val);
      this.pickupGift = val;
      this.goodsData = []
    },
    /**时间范围选择 */
    datatimeChange (time) {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.baseInfoForm.starting_date =
            this.order_time_range[0] / 1000;
          this.baseInfoForm.ending_date =
            this.order_time_range[1] / 1000 +
            86400 - 1;
        } else {
          this.baseInfoForm.starting_date =
            this.order_time_range[0] / 1000;
          this.baseInfoForm.ending_date =
            this.order_time_range[1] / 1000 + 86400 - 1;
        }
      } else {
        this.baseInfoForm.starting_date = ''
        this.baseInfoForm.ending_date = ''
      }
    },
    /**复选框选中事件 */
    handleSelectionChange (val) {
      this.selectedData = val;
      if (val.length > 0) {
        let ids = [];
        val.forEach(item => {
          ids.push(item.id);
        });
        if (this.tableData.length === val.length) {//全部选中
          this.baseInfoForm.pickup_stations = '-1';
        } else { //部分选中
          let format = ids.toString();
          this.baseInfoForm.pickup_stations = `,${format},`;
        }
      } else {
        this.baseInfoForm.pickup_stations = ''
      }
    },
    /**全选 */
    handleSelectAll (selection) {
    },
    handleClose (item, type) {
      if (type == 1) {
        this.checkLabelList.splice(this.checkLabelList.indexOf(item), 1);
      } else {
        this.checkAreaList.splice(this.checkAreaList.indexOf(item), 1);
      }
    },
    addgiftlabel (item, type) {
      if (type == 1) {//添加标签
        if (this.checkLabelList.length > 0 && this.checkLabelList.some(k => k.label_id == item.label_id)) {
          return
        } else {
          this.checkLabelList.push(item);
        }
      } else {//添加区域偏好
        if (this.checkAreaList.some(k => k.label_id == item.label_id)) {
          return
        } else {
          this.checkAreaList.push(item);
        }
      }
    },
    async getTagLabel () {
      //1 礼包标签 2 区域偏好标签
      const giftlabelList = await getGiftLabelList({ type: 1, page_no: 1, page_size: 999 })
      this.giftlabelList = giftlabelList.data.data;
      const areaList = await getGiftLabelList({ type: 2, page_no: 1, page_size: 999 })
      this.areaList = areaList.data.data;
    },
    movement (data, move_type, req) {
      const params = {
        combo_id: this.comboId,
        goods_id: this.changeRow.id || data.goods_id,
        replace_goods_id: data.goods_id,
        move_type,
      };
      API_Combo[req](params).then((res) => {
        if (req === "moveComboGoodsTh") {
          this.GET_GoodData();
        } else {
          this.changeFun(this.changeRow);
        }

        console.log(res, this.goodsData);
      });
    },
    isnumber (e, key) {
      e = (e.match(/\d+(.\d{0,2})?/) || [""])[0];
      this.ruleForm[key] = e > 100000 ? 100000 : e;
      console.log(e, key);
    },
    setPrice (e) {
      this.baseInfoForm.market_sum_price = e;
      this.$forceUpdate();

      console.log(e);
    },
    handleDeleteCombo (row) {
      console.log(row);
      API_Combo.deleteReplaceGoodsTh(
        this.comboId,
        this.changeRow.id,
        row.id
      ).then((res) => {
        console.log(res);
        this.changeFun(this.changeRow);
        this.$message.success("删除成功");
      });
    },
    setIndex ($index, type) {
      type === 1
        ? (this.thumbnailIndex = $index)
        : (this.thumbnailIndex2 = $index);
      console.log(this.thumbnailIndex);
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_ComboList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_ComboList();
    },
    editChange () {
      // const goods_ids = this.pageData.data.map(item => {
      //   return item.goods_id;
      // });
      console.log(this.pageData.data);
      const goods_volist = this.pageData.data.map((item) => {
        return {
          exchange_goods_num: 0,
          goods_alias: item.goods_alias,
          goods_id: item.goods_id,
          goods_name: item.goods_name,
          num: item.num,
          shop_combo_id: this.comboId,
          web_big: item.web_big,
          web_original: item.web_original,
          web_small: item.web_small,
          web_thumbnail: item.web_thumbnail,
        };
      });
      console.log(goods_volist);
      API_Combo.addReplaceGoodsTh(this.comboId, this.changeRow.id, {
        goods_volist,
      }).then((res) => {
        console.log(res);
        this.$message.success("添加成功");
      });
    },
    //打开可替换弹窗
    openrelpace () {
      if (this.changeRow.goods_source == 3) {
        this.replaceType = 2
      } else {
        this.replaceType = 1  //去掉蛋糕页签
      }
      this.showChangeDialog = true
    },
    changeFun (e) {
      this.changeRow = e;
      this.changeDialog.display();
      console.log(this.changeRow);
      let params = { ...this.params };
      params[this.key_word] = params.keyWord;
      delete params.keyWord;
      API_Combo.replaceGoodsListTh(this.changeRow.id, params).then((res) => {
        this.pageData.data = res.map((item) => {
          return { ...item, delete: 1 };
        });
        console.log(this.pageData);
      });
    },
    thumbnailSuccess (res) {
      let goodsData = JSON.parse(JSON.stringify(this.goodsData))
      if (this.thumbnailIndex !== "") {
        goodsData[this.thumbnailIndex].web_thumbnail = res.url
        goodsData[this.thumbnailIndex].web_big = res.url
        goodsData[this.thumbnailIndex].web_small = res.url
        goodsData[this.thumbnailIndex].web_original = res.url
        this.goodsData = [...goodsData]
      } else {
        this.pageData.data[this.thumbnailIndex2].web_thumbnail = res.url;
        this.pageData.data[this.thumbnailIndex2].web_big = res.url;
        this.pageData.data[this.thumbnailIndex2].web_small = res.url;
        this.pageData.data[this.thumbnailIndex2].web_original = res.url;
      }
      console.log(res, this.goodsData, this.thumbnailIndex);
      console.log(this.goodsData[this.thumbnailIndex], res.url);
      this.thumbnailIndex = "";
      this.thumbnailIndex2 = "";
      //更改图片不重新计算价格
      // this.getPrice();
      // this.totalData = [this.baseInfoForm];
    },
    //防抖
    debounce (fn, delay = 300) {   //默认300毫秒
      let timer;
      return function () {
        let args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          fn.apply(this, args);   // this 指向vue
        }, delay);
      };
    },

    getPrice (type = 0, key = "") {
      console.log("价格改了");
      if (key === "") {
        this.baseInfoForm.change_shop_sum_price = 0
        this.baseInfoForm.change_enterprise_sum_price = 0
      } else {
        this.baseInfoForm[key] = type;
      }
      console.log(this.baseInfoForm);
      this.baseInfoForm = {
        ...this.baseInfoForm,
        ...this.goodsData.reduce(
          (p, c) => {
            p.combo_price =
              this.baseInfoForm.change_combo_price === 1
                ? this.baseInfoForm.combo_price
                : this.addPrice(
                  p.combo_price,
                  this.xPrice(c.revise_price, c.num)
                );
            p.shop_sum_price = this.baseInfoForm.change_shop_sum_price === 1 ? this.baseInfoForm.shop_sum_price : this.addPrice(
              p.shop_sum_price,
              this.xPrice(c.revise_price, c.num)
            );
            p.market_sum_price =
              this.baseInfoForm.change_market_price === 1
                ? this.baseInfoForm.market_sum_price
                : this.addPrice(
                  p.market_sum_price,
                  this.xPrice(c.mktprice, c.num)
                );
            p.enterprise_sum_price = this.baseInfoForm.change_enterprise_sum_price === 1
              ? this.baseInfoForm.enterprise_sum_price
              : this.addPrice(
                p.enterprise_sum_price,
                this.xPrice(c.enterprise_revise_price, c.num)
              );
            p.sum_price = this.addPrice(
              p.sum_price,
              this.xPrice(c.price, c.num)
            );
            console.log(typeof p.enterprise_sum_price);
            // 分销利润率计算
            if (p.enterprise_sum_price && Number(p.enterprise_sum_price) != 0) {
              p.distribution_profit = (
                ((p.enterprise_sum_price - p.sum_price) / p.enterprise_sum_price) *
                100
              ).toFixed(2)
            } else {
              p.distribution_profit = 0
            }
            // 销售利润率计算
            if (p.shop_sum_price && Number(p.shop_sum_price) != 0) {
              p.price_profit = (
                ((p.shop_sum_price - p.sum_price) / p.shop_sum_price) *
                100
              ).toFixed(2)
            } else {
              p.price_profit = 0
            }
            // 市场利润率计算
            p.market_profit = (
              ((p.market_sum_price - p.sum_price) / p.market_sum_price) *
              100
            ).toFixed(2)
            return p;
          },
          {
            combo_price: 0,
            /** 销售总价格 */
            shop_sum_price: 0,

            /** 市场总价格 */
            market_sum_price: 0,

            /** 分销总价格 */
            enterprise_sum_price: 0,

            /** 会员总价 */
            sum_price: 0,
            /** 利润率 */
            profit_price: 0,
          }
        ),
      };
      this.totalData = [this.baseInfoForm];
      this.$forceUpdate();
    },
    xPrice (a, num) {
      let ret = 0;
      for (let index = 0; index < num; index++) {
        ret = this.addPrice(ret, a);
      }
      return ret;
    },
    /**
     * 两位小数加法
     */
    addPrice (a, b) {
      a = a.toString();
      b = b.toString();
      a.split(".")[1]
        ? a.split(".")[1].length === 1 && (a += "0")
        : (a += "00");
      b.split(".")[1]
        ? b.split(".")[1].length === 1 && (b += "0")
        : (b += "00");
      return (Number(a.replace(".", "")) + Number(b.replace(".", ""))) / 100;
    },
    handleDelete (index, row) {
      this.goodsData.splice(index, 1);
    },
    /** 保存商品选择器选择的商品 */
    allChoose (list) {
      const originGoodsId = this.goodsData.map((item) => {
        return item.goods_id;
      });
      list.forEach((i) => {
        if (originGoodsId.indexOf(i.goods_id) === -1) {
          this.$set(i, "num", 1);
          this.$set(i, "goods_alias", i.goods_name);
          this.$set(i, "not_put", true);
          this.goodsData.push(i);
        }
      });
      this.totalData[0] = this.baseInfoForm;
    },
    allChangeChoose (list) {
      const originGoodsId = this.pageData.data.map((item) => {
        return item.goods_id;
      });
      list.forEach((i) => {
        if (originGoodsId.indexOf(i.goods_id) === -1) {
          this.$set(i, "num", 1);
          this.$set(i, "goods_alias", i.goods_name);
          this.pageData.data.push(i);
        }
      });
      this.totalData[0] = this.baseInfoForm;
    },
    /** 返回*/
    pre () {
      this.loading = true;
    },

    /** 上架  */
    aboveGoods () {
      if (!this.baseInfoForm.shop_sum_price) return this.$message.error("请先设置销售总价")
      if (!(!this.MixinIsFormEnterprise && (this.ispetroChina||this.$store.getters.shopInfo.shop_id==1595)) && !this.baseInfoForm.enterprise_sum_price) return this.$message.error("请先设置分销总价")
      let _params = this.generateFormData(this.baseInfoForm);
      //判断是否有超过1种蛋糕
      const cakes = _params.goods_volist.filter(i => i.goods_source === 3)
      if (cakes.length > 1) {
        this.$message.error("礼包内蛋糕商品种类不可超过1种");
        return;
      }
      // 检验礼包内商品的别名不能为空
      if (!this.goodsData.every((i) => i.goods_alias)) {
        this.$message.error("请补充完整礼包的前端名称");
        return;
      }
      this.createGoodsButtonLoading = true;
      this.$refs["baseInfoForm"].validate((valid) => {
        if (valid) {
          if ([2, 4].includes(_params.delivery_method)) {//网点自提   
            console.log(_params);
            _params.time_slot = `${_params.time_slot[0]}_${_params.time_slot[1]}`
            if (_params.type == 1) {
              const { type, starting_date, ending_date, time_slot } = _params;
              const obj = { type, starting_date, ending_date, time_slot }
              _params.pickup_time = JSON.stringify(obj)
              if (_params.order_deadline_time) {
                _params.order_deadline_time = _params.order_deadline_time / 1000
              }
            } else {
              const { type, lead_time, pickup_days, time_slot } = _params;
              const obj = { type, lead_time, pickup_days, time_slot }
              _params.pickup_time = JSON.stringify(obj)
              _params.order_deadline_time = null
            }
          }
          if (this.checkAreaList.length > 0) {
            const label_ids_area = this.checkAreaList.map(item => item.label_id)
            _params.label_ids_area = label_ids_area.toString()
          }
          if (this.checkLabelList.length > 0) {
            const label_ids_gift = this.checkLabelList.map(item => item.label_id)
            _params.label_ids_gift = label_ids_gift.toString()
          }
          if (Array.isArray(_params.category_path)) _params.category_path = _params.category_path.at(-1)
          console.log(11111111111111, _params.category_path);
          const categoryUrl = _params.category_path.split("|");
          _params.category_id = categoryUrl[categoryUrl.length - 2]
          if (this.comboId) {
            if (_params.delivery_method == 3) {
              _params.delivery_method = 1
            }
            /** 修改礼包 */
            API_Combo.editCombo(this.comboId, _params)
              .then(() => {
                this.$message.success("修改礼包成功");
                this.firstPriceShowFlag = true;
                this.GET_GoodData();
                // this.$store.dispatch("delCurrentViews", {
                //   view: this.$route,
                //   $router: this.$router
                // });
                // this.goodsData.forEach(i => {
                //   delete i.not_put
                // });
              })
              .finally(() => {
                this.createGoodsButtonLoading = false;
              });
          } else {
            if (_params.delivery_method == 3) {
              _params.delivery_method = 1
            }
            /** 创建礼包 */
            API_Combo.addCombo(_params)
              .then((res) => {
                this.comboId = res.id;
                this.$message.success("创建礼包成功");
                this.firstPriceShowFlag = true;
                this.GET_GoodData();
                // this.$store.dispatch("delCurrentViews", {
                //   view: this.$route,
                //   $router: this.$router
                // });
                // this.goodsData.forEach(i => {
                //   this.$set(i, "not_put", false);
                // });
              })
              .finally(() => {
                this.createGoodsButtonLoading = false;
              });
          }
        } else {
          this.createGoodsButtonLoading = false;
          this.$message.error(
            "表单中存在未填写或者填写有误的地方,已有错误标示，请检查并正确填写"
          );
        }
      });
    },

    /** 搜索单个商品信息*/
    async GET_GoodData () {
      await API_Combo.getCombo(this.comboId).then((response) => {
        this.checkAreaList = [];
        this.checkLabelList = [];
        if (response.label_ids_area) {
          const label_ids_area = response.label_ids_area.split(',').map(Number)
          this.checkAreaList = this.areaList.filter((item) => {
            if (label_ids_area.includes(item.label_id)) return item
          })
        }
        if (response.label_ids_gift) {
          const label_ids_gift = response.label_ids_gift.split(',').map(Number)
          this.checkLabelList = this.giftlabelList.filter((item) => {
            if (label_ids_gift.includes(item.label_id)) return item
          })
        }
        if ([2, 4].includes(response.delivery_method)) {
          if (response.order_deadline_time) {
            this.deadline = 2
            response.order_deadline_time = response.order_deadline_time * 1000
          }
          const pickup_time = JSON.parse(response.pickup_time)
          let time_slot = pickup_time.time_slot.split('_')
          response.time_slot = time_slot
          if (pickup_time.type == 1) {
            response.starting_date = pickup_time.starting_date
            response.ending_date = pickup_time.ending_date
            response.type = 1
            this.order_time_range = [pickup_time.starting_date * 1000, pickup_time.ending_date * 1000]
          } else {
            response.lead_time = pickup_time.lead_time
            response.pickup_days = pickup_time.pickup_days
            response.type = 2
          }
        } else {//判断是
          if (response.goods_volist[0].shop_goods_vo.goods_do.seller_id == this.seller_id) {//商城邮寄商品
            response.delivery_method = 3
          }
        }
        this.baseInfoForm = {
          ...response,
        };
        this.pickupGift = this.baseInfoForm.delivery_method;
        this.baseInfoForm["profit_price"] = (
          ((this.baseInfoForm["shop_sum_price"] -
            this.baseInfoForm["sum_price"]) /
            this.baseInfoForm["shop_sum_price"]) *
          100
        ).toFixed(2);
        /** 商品相册校验属性 */
        this.baseInfoForm.goods_gallery_list.forEach((key) => {
          this.$set(key, "url", key.original);
        });
        this.$nextTick(() => {
          this.setSort();
        });
        this.baseInfoForm.goods_gallery =
          this.baseInfoForm.goods_gallery_list.toString();
        this.goodsGalleryList = JSON.parse(
          JSON.stringify(this.baseInfoForm.goods_gallery_list)
        );
        this.baseInfoForm.category_path = this.handlePath(this.baseInfoForm.category_path)
        console.log(22222222222, this.baseInfoForm.category_path);
        this.goodsData = this.genGoodsData(response.goods_volist);
        this.totalData = [this.baseInfoForm];
        this.$forceUpdate();
      });
      if (this.baseInfoForm.delivery_method == 2 || this.baseInfoForm.delivery_method == 4) {
        //回显门店复选框
        let selecttable = this.baseInfoForm.pickup_stations.split(',')
        selecttable = selecttable.filter(item => item != '')
        selecttable = selecttable.map(Number)
        if (selecttable == -1) {
          this.selectedData = this.tableData;
          console.log(this.tableData);
        } else {
          this.selectedData = this.tableData.filter(item => selecttable.includes(item.id))
        }
        const that = this
        this.$nextTick(() => {
          setTimeout(() => {
            that.selectedData.map(item => {
              that.$refs.multipleTable.toggleRowSelection(item, true);
            });
          }, 100)
        })
      }
    },
    handlePath (path) {
      if (typeof path !== 'string') return path;
      const res = []
      let start = 0
      let end = 2
      const nPath = path.split('|')
      let len = nPath.length
      if (!len) return [];
      while (end < len) res.push(`${nPath.slice(start, end++).join('|')}|`)
      return res
    },
    /** 文件列表  图片个数 校验*/
    handleExceed (files, fileList) {
      this.$message.warning(`最多上传5张图`);
    },
    /** 文件列表移除文件时的钩子  图片删除校验*/
    handleRemove (file, fileList) {
      this.baseInfoForm.goods_gallery_list.forEach((key, index) => {
        if (key.img_id !== -1) {
          if (key.img_id === file.img_id) {
            this.baseInfoForm.goods_gallery_list.splice(index, 1);
          }
        } else {
          if (key.name === file.response.name) {
            this.baseInfoForm.goods_gallery_list.splice(index, 1);
          }
        }
      });
      if (fileList.length <= 0) {
        this.baseInfoForm.goods_gallery_list = [];
        this.baseInfoForm.goods_gallery =
          this.baseInfoForm.goods_gallery_list.toString();
      }
      this.$refs.baseInfoForm.validateField("goods_gallery");
    },

    /** 文件列表上传成功时的钩子  上传成功校验 */
    handleSuccess (response, file, fileList) {
      // this.baseInfoForm.goods_gallery_list.push({
      //   img_id: -1,

      //   original: response.url,

      //   url: response.url,

      //   sort: 0,

      //   name: response.name,
      // });
      this.baseInfoForm.goods_gallery_list = fileList.map(items => {
        return {
          img_id: -1,
          original: items.response.url,
          url: items.response.url,
          sort: 0,
          name: items.response.name
        }
      })
      this.baseInfoForm.goods_gallery =
        this.baseInfoForm.goods_gallery_list.toString();
      this.$refs["baseInfoForm"].validateField("goods_gallery");
      this.$nextTick(() => {
        this.setSort();
      });
    },

    /** 点击已上传的文件链接时的钩子 放大 */
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogImage = true;
    },

    /** 拖拽改变图片顺序 */
    setSort () {
      const el = document.querySelectorAll(
        "div.avatar-uploader > ul.el-upload-list--picture-card"
      )[0];
      this.sortable = Sortable.create(el, {
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) { },
        onEnd: (evt) => {
          let temp = this.baseInfoForm.goods_gallery_list[evt.oldIndex];
          this.baseInfoForm.goods_gallery_list[evt.oldIndex] =
            this.baseInfoForm.goods_gallery_list[evt.newIndex];
          this.baseInfoForm.goods_gallery_list[evt.newIndex] = temp;
        },
      });
    },
    handleSortChange ({ prop, order }) {
      if (order) {
        this.params.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"}`;
      } else {
        this.params.sort = "";
      }
      this.changeFun(this.changeRow);
    },
    /** 图片上传之前的校验  */
    beforeAvatarUpload (file) {
      const isType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (!isType) {
        this.$message.error("上传礼包图片只能是 JPG/JPEG/PNG 格式!");
      }
      if (!isLt1M) {
        this.$message.error("上传礼包图片大小不能超过 1MB!");
      }

      /** 重复校验*/
      const isExit = this.baseInfoForm.goods_gallery_list.some((key) => {
        return file.name === key.name;
      });
      if (isExit) {
        this.$message.error("图片已存在");
      }

      return isType && isLt1M && !isExit;
    },

    /** 构造表单数据 */
    generateFormData (data) {

      let _params = {
        ...data,
        goods_volist: [
          ...this.goodsData.reduce((p, c) => {
            let {
              goods_alias,
              goods_id,
              goods_name,
              num,
              web_thumbnail,
              thumbnail,
              goods_source
              // id
            } = c;
            p = [
              ...p,
              {
                goods_alias,
                goods_id,
                goods_name,
                num,
                web_thumbnail: web_thumbnail || thumbnail,
                goods_source
                // id
              },
            ];
            return p;
          }, []),
        ],
      };
      delete _params.gallery_list;
      delete _params.goods_gallery;
      if (_params.goods_gallery_list) {
        _params.goods_gallery_list.forEach((key) => {
          delete key.name;
          delete key.big;
          delete key.goods_id;
          delete key.isdefault;
          delete key.small;
          delete key.status;
          delete key.thumbnail;
          delete key.tiny;
          delete key.uid;
          delete key.url;
        });
      }

      /** 处理UE的信息 */
      _params.intro = this.$refs["ue"].getUEContent();
      return _params;
    },
    /**
     * 编辑回显构造商品数据
     */
    genGoodsData (goods_volist) {
      let ret = [];
      goods_volist.forEach((item) => {
        let profit_price = 0;
        let {
          goods_id,
          goods_name,
          supplier_name,
          goods_alias,
          num,
          id,
          seq,
          exchange_goods_num,
          web_thumbnail,
          web_gift_name,
          shop_goods_vo: {
            enterprise_revise_price,
            goods_do: {
              thumbnail,
              ext_goods_id,
              goods_source,
              mktprice,
              price,
              enable_quantity,
              self_operated,
              sn,
              enterprise_profit
            },
            revise_price,
            shop_goods_status,
            disabled, // 值等于0时表示大平台下架商品
            shop_profit
          },
        } = item;
        ret.push({
          sn,
          id,
          goods_alias,
          web_gift_name,
          exchange_goods_num,
          web_thumbnail,
          goods_id,
          thumbnail,
          goods_name,
          supplier_name,
          ext_goods_id,
          goods_source,
          revise_price,
          mktprice,
          enterprise_revise_price,
          price,
          num,
          seq,
          enable_quantity,
          shop_goods_status,
          disabled,
          profit_price,
          self_operated,
          enterprise_profit,
          shop_profit
        });
      });

      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
.changBtn {
  position: absolute;
  top: 20px;
  left: 6px;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.2);
}

.content-goods-publish {
  padding: 15px;
  margin: 0 auto;
  text-align: center;
  background: none repeat 0 0 #fff;
  border-radius: 5px;

  /deep/.el-table {
    .el-table__fixed,
    .el-table__fixed-right {
      height: auto !important;
      bottom: 8px;
    }

    th {
      background: #e6ecf7;
      color: #333;
      font-weight: 600;
      line-height: 1;
      padding: 8px 0;
    }

    .cell {
      line-height: 1.2;
    }

    .el-table--border th {
      border-right: 1px solid rgba(220, 223, 230, 1);
    }

    .el-table--border td {
      border-right: 1px solid rgba(220, 223, 230, 1);
    }
  }
}

/*编辑基本信息*/
.el-form {
  padding-bottom: 80px;

  .el-form-item {
    // width: 100%;
    text-align: left;
  }
}

div.base-info-item {
  .el-form-item {
    width: 30%;
    min-width: 300px;
  }

  .goods-name-width {
    width: 50%;
    min-width: 300px;
  }
}

.goods-select ::v-deep .el-form-item__content {
  margin-left: 50px !important;
}

/*描述*/
.goods-intro {
  * {
    line-height: 1;
  }
}

/** 底部步骤 */
.footer {


  position: fixed;
  bottom: 20px;
  left: 50%;
  z-index: 999;


}

/*图片上传组件第一张图设置封面*/
.goods-images {
  /deep/ li.el-upload-list__item:first-child {
    position: relative;
  }

  /deep/ li.el-upload-list__item:first-child:after {
    content: "封";
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    position: absolute;
    left: -15px;
    top: -6px;
    width: 40px;
    height: 24px;
    padding-top: 6px;
    background: #13ce66;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
  }
}

.no-plus-icon ::v-deep {
  .el-upload--picture-card {
    display: none;
  }
}

::v-deep .el-input-group__append {
  padding: 0 10px;
  color: #333;
  background: #eaf0ff;
  border: 0;
}

::v-deep .price-input .el-input__inner {
  border: 0;
}

.price-input {
  width: 130px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  overflow: hidden;
}

.page {
  .el-card:nth-child(1) {
    /deep/ .el-card__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px 20px;

      .button-back {
        height: 40px;

        .back_btn {
          width: 36px;
          height: 36px;
          background-color: #1a43a9;
          border-radius: 50%;

          img {
            width: 10px;
            height: 20px;
            position: relative;
            top: -2px;
            left: -4px;
          }
        }
      }
    }

    /deep/ .el-form-item__content {
      table {
        border: 1px solid #e6ecf7;

        tr>th {
          text-align: center;
          background-color: #e6ecf7;
        }

        tr>td {
          padding: 10px 5px;
          text-align: center;

          input {
            width: 100%;
          }

          .el-input__inner {
            padding: 0 5px;
          }
        }

        tr {

          .card-name,
          .card-section {
            min-width: 200px;
          }

          .icon-btn {
            border: 0;
            padding: 0;
            margin-left: 0;

            .el-icon-circle-plus-outline,
            .el-icon-remove-outline {
              font-size: 22px;
            }
          }
        }
      }
    }

    .card-info {
      /deep/ .el-form-item__label {
        display: inline-block !important;
        float: none !important;
      }

      /deep/ .el-form-item__content {
        margin-left: 55px !important;
      }
    }
  }

  .el-card {
    /deep/ .el-card__header {
      .title:before {
        content: " ";
        display: inline-block;
        position: relative;
        left: -20px;
        width: 5px;
        height: 20px;
        background: #1a43a9;
        transform: translate(0, 20%);
      }
    }

    margin-bottom: 20px;

    .card-body {
      .el-form-item {
        .el-form-item__content {
          .left {
            width: 200px;
          }

          .right {
            position: absolute;
            top: 0;
            left: 240px;
          }
        }
      }
    }
  }
}

/deep/ .el-radio-group {
  margin-top: 6px;
}

.check_taglabel {
  border: 1px solid #eee;
  width: 710px;
  padding: 5px 10px 0px 10px;
  min-height: 47px;
}

.select_taglabel {
  padding: 5px 10px 10px 10px;
  background: #eeeeee61;
  border: 1px solid #eee;
  width: 710px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  button {
    margin: 0;
  }
}
</style>
